import foundation from 'foundation-sites';
import Navigation from './components/navigation'
import Countdown from './components/countdown'
import SmoothAnker from './components/smoothanker'

let NavigationObj = new Navigation();
let SmoothAnkerObj = new SmoothAnker();

let date = document.querySelector('#countdown')?.getAttribute('data-date');
//let CountdownObj = new Countdown(date);


// import Klaro without CSS
import * as Klaro from 'klaro/dist/klaro-no-css'
import Config from './vendor/config';

let ConfigObj = new Config();
window.klaro = Klaro;
window.klaroConfig = ConfigObj.getConfig();
// we set up Klaro with the config
Klaro.setup(ConfigObj.getConfig());