export default class Countdown  {
    
    constructor(time) {
        let countDownDate = new Date(time).getTime();
        this.render(countDownDate);
    }
    
    render(countDownDate) {   
        let x = setInterval(function() {

            // Get today's date and time
            let now = new Date().getTime();
              
            // Find the distance between now and the count down date
            let distance = countDownDate - now;
              
            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
              
            // Output the result in an element with id="demo"
            document.getElementById("countdown").innerHTML = "<label><span>"+days + "</span>TAGE</label><label><span>" + hours + "</span>STUNDEN</label><label><span>"
            + minutes + "</span>MINUTEN</label><label><span>" + seconds + "</span>SEKUNDEN</label>";
              
            // If the count down is over, write some text 
            if (distance < 0) {
              clearInterval(x);
              document.getElementById("countdown").innerHTML = "EXPIRED";
            }
          }, 1000);
    } 
}






// Update the count down every 1 second
