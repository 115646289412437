const doc = document;
let ankerEls = '';
let obj = '';
let menu = false;

export default class SmoothAnker  {
    constructor() {
        ankerEls = doc.querySelectorAll('.smooth');
        obj = this;
        this.render();
    }
    
    render() {   
        ankerEls.forEach(ankerElement => {
            ankerElement.addEventListener('click', event => {
                let scrollId = ankerElement.getAttribute('href');  
                let patt = /#(.*)/i;
                let id = scrollId.match(patt);
                obj.scrollTo(document.getElementById(id[1]));
                event.preventDefault();   
            });
        });
    } 

    scrollTo(element) {
        if(element != null || typeof element != undefined){
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: element?.offsetTop - 35
            });
        }        
    }
}


