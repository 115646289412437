const doc = document;
let navElements = '';
let obj = '';
let menu = false;

export default class Navigation  {
    constructor() {
        navElements = doc.querySelectorAll('.menu a');
        obj = this;

        document.querySelector('#burger').addEventListener('click', ()=>{
            this.openMobileMenu();   
        });

        this.render();
    }
    
    render() {   
        navElements.forEach(navElement => {
            navElement.addEventListener('click', event => {
                let scrollId = navElement.getAttribute('href');        
                obj.scrollTo(document.getElementById(scrollId.substring('1')));
                event.preventDefault();   
            });
        });
    } 

    openMobileMenu(){
        if(!menu){
            document.querySelector('#burger img').setAttribute('src', 'img/assets/x.svg');
            document.querySelector('#main-navigation .menu').style.display = 'block';
            document.querySelector('#main-navigation .menu').style.opacity = '1';
            menu = true;    
        }else{
            document.querySelector('#burger img').setAttribute('src', 'img/assets/burger.svg');
            document.querySelector('#main-navigation .menu').style.display = 'none';
            document.querySelector('#main-navigation .menu').style.opacity = '0';      
            menu = false;
        } 
    }

    scrollTo(element) {
        if(menu){
            document.querySelector('#burger img').setAttribute('src', 'img/assets/burger.svg');
            document.querySelector('#main-navigation .menu').style.display = 'none';
            document.querySelector('#main-navigation .menu').style.opacity = '0';      
            menu = false;
        }
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: element.offsetTop - 35
        });
    }
}


