


export default class Config  {
    
    constructor() {       
        
    }

    getConfig() {
        let hostname = window.location.hostname;
        let patt = /.*\.(.*\.de)/i;
        let domain = hostname.match(patt);
        

        if(domain == null){
            domain = ['', 'mannheimer-morgen.de'];
        }

        
        let klaroConfig = {
            elementID: 'klaro',
            storageMethod: 'cookie',
            cookieName: 'klaro_cmt',
            cookieExpiresAfterDays: 365,
            privacyPolicy: '//www.'+domain[1]+'/datenschutzerklaerung.html',
            default: false,
            mustConsent: false,
            acceptAll: true,
            hideDeclineAll: true,
            lang: 'de',
            translations: {
                de: {
                    consentNotice: {
                        description: 'Wir speichern und verarbeiten Informationen für folgende Zwecke: {purposes}.\n'
                    },
                    consentModal: {
                        description: 'Hier können Sie einsehen und anpassen, welche Informationen wir sammeln.'
                    },
                    ok: 'Alle erlauben',
                    acceptAll: 'Alle erlauben',
                    acceptSelected: 'Auswahl speichern',
                    necessary: {
                        description: 'Zur technischen Funktionsfähigkeit der Website notwendige Cookies. Hier werden keine persönlichen Daten erhoben.'
                    },
                    googleAnalytics: {
                        description: 'Sammelt anonymisierte Daten darüber, wie Sie unsere Website benutzen. Diese Daten helfen uns dabei, unsere Dienste für Sie zu optimieren.'
                    },
                    facebook: {
                        description: 'Sammelt Daten darüber, wie Sie unsere Website benutzen. Diese Daten helfen uns dabei, unsere Dienste für Sie zu optimieren.'
                    },
                    purposes: {
                        necessary: 'Funktionsfähigkeit',
                        analytics: 'Besucher-Statistiken',
                        security: 'Sicherheit',
                        livechat: 'Live Chat',
                        advertising: 'Anzeigen von Werbung',
                        styling: 'Styling'
                    }
                }
            },
            services: [
                {
                    name: 'necessary',
                    default: true,
                    title: 'System',
                    purposes: ['necessary'],
                    cookies: [],
                    required: true,
                    optOut: false,
                    onlyOnce: true
                },
                {
                    // Each app should have a unique (and short) name.
                    name: 'googleAnalytics',
                    default: false,
                    title: 'Google Analytics',
                    purposes: ['analytics'],
                    callback: function(consent, app) {
                        // This is an example callback function.
                        //console.log(
                        //    'User consent for app ' + app.name + ': consent=' + consent
                        //);
                    },
                    required: false,
                    optOut: false,
                    onlyOnce: true
                },
                {
                    // Each app should have a unique (and short) name.
                    name: 'googleTag',
                    default: false,
                    title: 'Google Tag Manager',
                    purposes: ['analytics'],
                    callback: function(consent, app) {
                        // This is an example callback function.
                        //console.log(
                        //    'User consent for app ' + app.name + ': consent=' + consent
                        //);
                    },
                    required: false,
                    optOut: false,
                    onlyOnce: true
                }
            ]
        };

        return klaroConfig;
        
    }
}
